import { message, Button, Form, Divider } from "antd";
import InputComponent from "components/Input.component";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";



const ForgotPage:React.FC = () => {
  
  const navigate: any = useNavigate()
  const dispatch: any = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const [forgotForm] = React.useState({
    email: null,
  })

  const validateMessages = {
    required: '${label} est requis',
    types: {
      email: '${label} n\'est pas un email valide',
      number: '${label} n\'est pas un numéro valide',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const handleForgotForm = React.useCallback(()=>{
    
  },[forgotForm])


  return (
    <div className="login-page flex-1 shadow-lg bg-white rounded-md p-5 mx-5">
      <div className="page-wrapper">
        <div className="form">
          <div className="form-wrapper flex flex-col gap-5">
            <div className="form-header flex flex-col items-center justify-center gap-2">
              <img className="w-20 h-20 rounded-lg overflow-hidden" src={require('./../../assets/imgs/jobito.png')} alt="" />
              <h1 className="uppercase font-semibold text-2xl text-primary">Bienvenue</h1>
              <p className="text-sm">Veuillez s'identifier pour accéder au tableau de bord</p>
            </div>
            <div className="form-body">
              <Form
                validateMessages={validateMessages}
                form={form}
                onFinish={handleForgotForm}
                initialValues={{ ...forgotForm }}
                className="flex flex-col gap-4" 
                layout="vertical">
                <InputComponent 
                  inputClassName="!bg-slate-200 hover:bg-slate-200"
                  size="large"
                  name="email"
                  rules={[
                    {required:true}
                  ]}
                  labelClassName="m-0" 
                  label="Email"/>
              </Form>
            </div>
            <div className="form-footer flex flex-col">
              <Button onClick={()=>form.submit()} className="bg-primary text-white border-none self-end hover:!text-white" >Envoyer</Button>
              <Divider  className="my-2"/>
              <div className="flex items-center justify-between">
                <p className="text-xs">Vous souvenez de votre mot de passe ?</p>
                <Button onClick={()=>navigate('/')} type="link" className="text-xs text-secondary hover:!text-primary" >Se connecter</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  
}

export default ForgotPage