import { combineReducers } from "redux";
import appReducer from "./reducers/app.reducer";
import persistReducer from "redux-persist/es/persistReducer";
import { PersistConfig, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import userReducer from "./reducers/user.reducer";
import validationReducer from "./reducers/validation.reducer";

const persistConfig:PersistConfig<any> = {
  key:"root",
  storage,
}

const reducers = combineReducers({
  app:appReducer,
  user:userReducer,
  validation:validationReducer
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const  store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false}).concat(thunk),
})

export const persistor = persistStore(store)