import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { authService } from "shared/services/auth.service";
import { setAuthToken } from "shared/utils/localstorage.util";
import storeTypes from "store/type";

interface ILogin {
  data:object
}


export const login = ({data}:ILogin) => async (dispatch: Dispatch<any>) => {

  try {
    
    const res = await authService.login({data:data})

    if (res.data.error) return {label:'Connexion',message:"Veuillez vérifier votre email ou mot de passe", error:true}

    if(!res.data.error) {

      let data = res.data.data
      if(!data?.user?.isAdmin) return {label:'Connexion', message:"Vous n'êtes pas autorisé a se connecter", error:true}

      await dispatch({type:storeTypes.app.user,payload:data?.user})
      await setAuthToken(data?.token)

      return {label:'Connexion', message:"Vous êtes connecté", error:false}
    }

    return {label:'Connexion', message:"Vérifier vos crédentials", error:true}

  } catch (error) {
    console.log(error);
    return {label:'Connexion', message:"Une erreur est survenue", error:true}
  }

  

}