import { Outlet } from "react-router-dom"


const AuthLayout:React.FC = () => {
  return(
    <div className="auth-layout min-h-screen flex flex-col bg-slate-50">
      <div className="layout-wrapper flex flex-1">
        <div className="relative layout-auth  flex-1 flex flex-col gap-5 justify-center items-center bg-slate-50">
          <img className="w-20 h-20 block lg:hidden object-cover rounded-[15px] overflow-hidden" src={require('./../assets/imgs/jobito.png')} alt="" />
          <div className="z-0">
            <Outlet />
          </div>

        </div>
        <div className="layout-info flex-1 hidden lg:flex bg-primary justify-center items-center">
          <img className="w-64 h-64 object-cover rounded-[50px] overflow-hidden" src={require('./../assets/imgs/jobito.png')} alt="" />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout