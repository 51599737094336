
import HomePage from "pages/admin/Home.page";
import { createBrowserRouter } from "react-router-dom";
import { adminRoutes } from "./admin.routes";
import { authRoutes } from "./auth.routes";
import React from "react";
import AuthLayout from "layouts/Auth.layout";
import AdminLayout from "layouts/Admin.layout";

const AuthComponent = React.lazy(() => import('./../components/Lazy.component'));

const router = createBrowserRouter([
  {
    path: '',
    element: <AuthLayout />,
    children: authRoutes,
  },
  {
    path: 'admin',
    element: <AdminLayout params={{role:'admin'}} />,
    children: adminRoutes
  },
])

export default router