import { NavigateFunction } from "react-router-dom";
import { userService } from "shared/services/user.service";
import { getAuthToken } from "shared/utils/localstorage.util";


export const authMiddleware = async ({navigate,params={}}:{navigate:NavigateFunction,params:object}) => {
  try {
    let token = await getAuthToken()
    let res = await userService.current()
    if(res.data.error) return false
    if(!token) return false
    let user = res.data.data.isAdmin == true
    if(!user) return false
    return true
  } catch (error) {
    return false
  }
}