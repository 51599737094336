import { Button, Space, Switch, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import SwitchStatusComponent from "components/Switch.component"
import moment from "moment"
import React from "react"
import { RiCheckLine, RiCrossLine } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { UseSelector } from "react-redux/es/hooks/useSelector"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Dispatch } from "redux"
import { list, update } from "store/actions/user.action"
import storeTypes from "store/type"

const ListPage: React.FC = () => {

  const navigate: any = useNavigate()
  const dispatch: any = useDispatch()
  const userState: any = useSelector((state: any) => state.user)

  const [isTreated, setIsTreated] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)

  React.useEffect(() => {
    dispatch(list({ params: { pagination: true, role: "company" } }))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(list({ params: { pagination: true, role: "company", isTreated: isTreated } }))
  }, [isTreated, dispatch])

  const handleActivation = async (id: string, isActive: boolean) => {
    await dispatch(update({ id: id, data: { isActive: isActive } }))
    dispatch(list({ params: { pagination: true, role: "company" } }))
  }
  
  const fetchData = () => {
    dispatch(list({ params: { pagination: true, role: "company", isTreated: isTreated, page:currentPage } }))
  }


  React.useEffect(() => {
    fetchData()
  }, [currentPage])

  const columns: ColumnsType<any> = [
    {
      title: 'Type',
      dataIndex: 'role',
      className: '!bg-white',
      key: '_id',
      render: (text) => <a>{text=="candidate"?"Candidat":"Entreprise"}</a>,
    },
    {
      title: 'Nom',
      dataIndex: 'role',
      className: '!bg-white',
      key: '_id',
      render: (text,data) => {
        /* return <span>{ JSON.stringify(data) }</span> */
        return <span>{ data.role=="candidate"? (data?.candidateProfile?.firstName??'--')+" "+(data?.candidateProfile?.lastName??'--') : (data?.companyProfile?.companyName??"--") }</span>
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      className: '!bg-white',
      key: '_id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Date de création',
      dataIndex: 'createdAt',
      className: '!bg-white',
      key: '_id',
      render: (text) => <a>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</a>,
    },
    {
      title: 'Statut',
      dataIndex: 'isActive',
      className: '!bg-white',
      key: '_id',
      render: (text, data) => <SwitchStatusComponent isActive={data?.isActive} isTreated={data?.isTreated ?? false} />,
    },
    {
      title: 'Actions',
      width: 100,
      className: '!bg-white',
      dataIndex: '_id',
      key: '_id',
      render: (text, data) => {
        return (
          <div className="flex flex-col gap-2">
            {/* <Button onClick={()=>handleActivation(text,!data.isActive)} className={(data.isActive?'bg-secondary':'bg-green-500')+" text-white hover:!text-white border-none"}>{data.isActive?'Bloquer':'Activer'}</Button> */}
            <Button onClick={() => navigate('/admin/users/' + text)} className="bg-slate-500 text-white hover:!text-white border-none">Détails</Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="user-page">
      <div className="page-wrapper">
        <div className="page-header mb-5">
          <Switch checkedChildren="Traité" checked={isTreated} onChange={() => setIsTreated(!isTreated)} className="bg-slate-600" unCheckedChildren="Non traité" defaultChecked />
        </div>
        <Table
            rowKey="_id"
            size="small"
            showHeader={true}
            dataSource={userState.list}
            key="_id"
            locale={{ emptyText: 'pas de données' }}
            pagination={{
              total: userState.pagination?.total ?? 0,
              onChange: (page, pageSize) => {
                console.log(page, pageSize)
                setCurrentPage(page)
              },
            }}
            columns={columns} />
      </div>
    </div>
  )

}

export default ListPage