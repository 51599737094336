


export const getAuthToken = async () => {

  let storageData = await localStorage.getItem('auth:token')
  return storageData?storageData:""
}

export const setAuthToken = async (token = "") => {
  await localStorage.setItem('auth:token',token)
}

export const deleteAuthToken = async () => {
  await localStorage.removeItem('auth:token')
}