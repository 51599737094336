import ArticlePage from "pages/admin/Article.page";
import DetailPage from "pages/admin/users/Detail.page";
import ListPage from "pages/admin/users/List.page";
import { RouteObject } from "react-router-dom";




export const adminRoutes: RouteObject[] = [
  {
    index:true,
    element: <ListPage />
  },
  {
    path: 'users',
    children: [
      {
        index: true,
        element: <ListPage />
      },
      {
        path: ':id',
        element: <DetailPage />
      }
    ]
  }
]