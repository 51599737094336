import axios from "axios"
import { env } from "core/env"
import { IService } from "shared/interfaces/service.interface"
import { axiosInstance } from "shared/utils/axios.util"
import { getAuthToken } from "shared/utils/localstorage.util"


const ENDPOINT = 'user'

export const userService = {

  list : async ({params={}}:IService) => {
    return axiosInstance.get(`${ENDPOINT}`,{params:{...params,admin:true}})
  },

  current : async () => {
    return axiosInstance.get(`${ENDPOINT}/current`)
  },

  update : async ({id,data}:IService) => {
    return axiosInstance.patch(`${ENDPOINT}/update/${id}`,data)
  },

  getById: async ({id}:IService) => {
    return axiosInstance.get(`${ENDPOINT}/${id}`)
  }
  
}