import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { Rule } from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import React, { CSSProperties, ReactNode } from "react";

const InputComponent:React.FC<{
  label?:string, 
  required?:boolean,
  placeholder?:string,
  labelClassName?:string,
  inputClassName?:string,
  bordered?:boolean,
  labelStyle?:CSSProperties|undefined,
  inputStyle?:CSSProperties|undefined,
  size?:SizeType,
  type?:string,
  showCount?:boolean,
  onChange?:any,
  onSearch?:any,
  maxLength?:number,
  rules?:Rule[]
  disabled?:boolean,
  value?:string|number|any,
  filterOption?:any,
  allowClear?:boolean,
  addonBefore?:ReactNode,
  showSearch?:boolean,
  addonAfter?:ReactNode,
  mode?:any,
  children?:any,
  fieldNames?:any,
  options?:Array<any>,
  defaultValue?:any,
  name?:any,
  showTime?:boolean,
  format?:string,
  onSelect?: any,
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement,
  onClear?: () => void,
  hourStep?: any,
  minuteStep?: any,
  disabledTime?: any,
  disabledDate?: any,
}> = ({
  label,
  required=false,
  placeholder="",
  labelClassName="",
  inputClassName="",
  bordered=false,
  value,
  addonAfter,
  addonBefore,
  fieldNames,
  maxLength,
  mode,
  options,
  defaultValue,
  disabled,
  children,
  name,
  rules,
  size="middle",
  type,
  showSearch,
  filterOption,
  allowClear,
  onChange,
  onSearch,
  showCount,
  labelStyle,
  onSelect,
  inputStyle,
  onClear,
  showTime=false,
  format="DD/MM/YYYY",
  dropdownRender,
  minuteStep,
  hourStep,
  disabledTime,
  disabledDate
}) => {


  const inputType = () => {
    switch(type) {
      case "textarea"   : return <TextArea 
                                    onChange={onChange} 
                                    value={value} 
                                    disabled={disabled} 
                                    bordered={bordered} 
                                    allowClear={allowClear} 
                                    maxLength={maxLength} 
                                    showCount={showCount} 
                                    className={inputClassName+" w-full"} 
                                    size={size} 
                                    placeholder={placeholder} />
      case "number"     : return <InputNumber 
                                    addonBefore={addonBefore} 
                                    addonAfter={addonAfter} 
                                    bordered={bordered} 
                                    onChange={onChange} 
                                    value={value} 
                                    disabled={disabled} 
                                    className={inputClassName+" w-full"} 
                                    size={size} 
                                    placeholder={placeholder} />
      case "select"     : return <Select 
                                    disabled={disabled} 
                                    showSearch={showSearch} 
                                    onSelect={onSelect}
                                    bordered={bordered} 
                                    filterOption={filterOption} 
                                    onSearch={onSearch} 
                                    onChange={onChange} 
                                    dropdownRender={dropdownRender}
                                    onClear={onClear}
                                    mode={mode}
                                    value={value}
                                    placeholder={placeholder}
                                    className={inputClassName+" w-full"} 
                                    allowClear={allowClear} 
                                    size={size} 
                                    options={options} 
                                    fieldNames={fieldNames} 
                                    defaultValue={defaultValue} />
      case "date"       : return <DatePicker
                                    showTime={showTime}
                                    disabled={disabled} 
                                    format={format}
                                    minuteStep={minuteStep}
                                    hourStep={hourStep}
                                    onChange={onChange} 
                                    disabledTime={disabledTime}
                                    disabledDate={disabledDate}
                                    bordered={true} 
                                    allowClear={allowClear} 
                                    className={inputClassName+" w-full"} 
                                    style={inputStyle}
                                    size={size} 
                                    placeholder={placeholder} />
      case "password"   : return <Input.Password 
                                    addonBefore={addonBefore} 
                                    addonAfter={addonAfter} 
                                    bordered={bordered} 
                                    disabled={disabled} 
                                    onChange={onChange} 
                                    autoComplete="false"
                                    style={inputStyle}
                                    className={inputClassName+" w-full"} 
                                    allowClear={allowClear} 
                                    size={size} 
                                    placeholder={placeholder} />
      case "color"      : return <Input 
                                    addonBefore={addonBefore} 
                                    addonAfter={addonAfter} 
                                    bordered={bordered} 
                                    disabled={disabled} 
                                    onChange={onChange} 
                                    type="color" 
                                    className={inputClassName+" w-full"} 
                                    allowClear={allowClear} 
                                    placeholder={placeholder} />
      case "checkbox"   : return <Checkbox disabled={disabled} >{children}</Checkbox>
      case "checkboxes" : return <Checkbox.Group 
                                    options={options}
                                    defaultValue={defaultValue}
                                    className={inputClassName}
                                    style={inputStyle}
                                    onChange={onChange} >{children}</Checkbox.Group>
      default           : return <Input 
                                    addonBefore={addonBefore} 
                                    addonAfter={addonAfter} 
                                    bordered={bordered} 
                                    type={type} 
                                    onChange={onChange} 
                                    value={value} 
                                    defaultValue={defaultValue}
                                    disabled={disabled} 
                                    allowClear={allowClear} 
                                    maxLength={maxLength} 
                                    showCount={showCount} 
                                    style={inputStyle}
                                    className={inputClassName+" w-full"} 
                                    size={size} 
                                    placeholder={placeholder} />
    }
  }

  return (
    <Form.Item label={label} name={name} rules={rules} required={required} className={labelClassName} style={labelStyle} noStyle={false}>
      { inputType() }
    </Form.Item>
  )
}

export default InputComponent;