import axios from "axios"
import { env } from "core/env"
import { IService } from "shared/interfaces/service.interface"
import { axiosInstance } from "shared/utils/axios.util"
import { getAuthToken } from "shared/utils/localstorage.util"


const ENDPOINT = 'validations/'

export const validationService = {

  list : async ({id}:IService) => {
    return axiosInstance.get(`${ENDPOINT}${id}`)
  },

  create : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}create`,data)
  },

}