import { IAction } from "shared/interfaces/action.interface"
import storeTypes from "store/type"


const initialState:any = {
  user:null
}

const appReducer = (state = initialState, action:IAction) => {

  switch (action.type) {
    case storeTypes.app.user:
      return state = {
        ...state,
        user: action.payload
      }
    default:
      return state = {...state}
  }
  
}

export default appReducer