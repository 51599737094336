import { Dispatch } from "react";
import { userService } from "shared/services/user.service";
import storeTypes from "store/type";


export const list = ({ params }: any) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await userService.list({ params: params })
    if (res.data.error) return false
    if (!res.data.error) {
      dispatch({ type: storeTypes.user.list, payload: res.data.data })
      dispatch({ type: storeTypes.user.pagination, payload: res.data.pagination })
    }
    return false

  } catch (error) {
    console.log(error);
    return false
  }

}

export const update = ({ id, data }: any) => async (dispatch: Dispatch<any>) => {

  console.log(id, data);


  try {

    const res = await userService.update({ id: id, data: data })
    if (res.data.error) return false
    if (!res.data.error) {
      console.log(res.data.data);

      return true
    }
    return false

  } catch (error) {
    console.log(error);
    return false
  }

}

export const getById = ({ id }: any) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await userService.getById({ id: id })
    dispatch({type:storeTypes.user.detail,payload:res.data.data})

  } catch (error) {
    console.log(error);
    return false
  }

}