import axios from "axios"
import { getAuthToken } from "./localstorage.util"
import { env } from "core/env";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = env.development.base_url
    config.headers.Authorization = `Bearer ${await getAuthToken()}`
    config.headers["Content-Type"] = "application/json"
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});