import { Dispatch } from "react";
import { validationService } from "shared/services/validation.service";
import storeTypes from "store/type";


export const list = ({ id }: any) => async (dispatch: Dispatch<any>) => {

  try {

    const res = await validationService.list({id:id})
    if (res.data.error) return false
    if (!res.data.error) {
      console.log(res.data.data);
      
      dispatch({ type: storeTypes.validation.list, payload: res.data.data })
      return true
    }
    return false

  } catch (error) {
    console.log(error);
    return false
  }

}

export const create = ({ data }: any) => async (dispatch: Dispatch<any>) => {
  try {

    const res = await validationService.create({ data:data})
    if (res.data.error) return false
    if (!res.data.error) {
      console.log(res.data.data);
      return true
    }
    return false

  } catch (error) {
    console.log(error);
    return false
  }

}