import { Button, Divider, Form, Popconfirm, Popover, Tag } from "antd"
import InputComponent from "components/Input.component"
import SwitchStatusComponent from "components/Switch.component"
import moment from "moment"
import React, { useState } from "react"
import { RiArrowGoBackLine } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getById, update } from "store/actions/user.action"
import { create, list } from "store/actions/validation.action"
import storeTypes from "store/type"

const DetailPage = () => {

  const navigate: any = useNavigate()
  const dispatch: any = useDispatch()
  const userState: any = useSelector((state: any) => state.user.detail)
  const validationState: any = useSelector((state: any) => state.validation.list)

  const [isOpen, setIsOpen] = React.useState(false)
  const [motif,setMotif] = React.useState('')

  let { id } = useParams();

  const initialiseData = () => {
    dispatch(getById({ id: id }))
    dispatch(list({id:id}))
  }

  React.useEffect(() => {
    initialiseData()
  }, [dispatch, id])

  React.useEffect(() => {
    return () => {
      dispatch({ type: storeTypes.user.clear })
      dispatch({ type: storeTypes.validation.clear })
    }
  }, [])

  const handleActivation = async (isActive: boolean) => {
    await dispatch(update({ id: id, data: { isActive: isActive } }))
    initialiseData()
  }

  const handleSendRejectMotif = async () => {
    await dispatch(create({data:{id:id,motif:motif,isActive:false,isTreated:true}}))
    setIsOpen(false)
    setMotif("")
    initialiseData()
  }

  const handleSendActivation = async () => {
    await dispatch(create({data:{id:id,motif:"Compte activé",isActive:true,isTreated:true}}))
    setIsOpen(false)
    setMotif("")
    initialiseData()
  }

  return (
    <div className={(userState ? "block" : "hidden") + " detail-page"}>
      <RiArrowGoBackLine className="text-2xl font-bold text-slate-700 cursor-pointer" onClick={()=>navigate('/admin')} />
      <div className={"page-wrapper flex justify-center w-full "}>
        <div className="flex flex-col w-1/2 justify-center gap-5">
          <div className="flex flex-col">
            <div className="flex flex-col sticky top-0 z-10">
              <div className="  shadow-lg  rounded-2xl p-4">
                <div className="flex-none sm:flex">
                  <div className=" relative h-32 w-32   sm:mb-0 mb-3">
                    <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKhTDn5CMgjvSn5fc1e5BoYkqLNciCajwfvA&usqp=CAU"} alt="aji" className=" w-32 h-32 object-cover rounded-2xl" />
                  </div>
                  <div className="flex-auto sm:ml-5 justify-evenly">
                    <div className="flex items-center justify-between sm:mt-2">
                      <div className="flex items-center">
                        <div className="flex flex-col">
                          <div className="w-full flex-none text-lg  font-bold leading-none">{userState?.role == "candidate"?(userState?.candidateProfile?.firstName??'--')+" "+(userState?.candidateProfile?.lastName??'--'):userState?.companyProfile?.companyName}</div>
                          <div className="flex flex-col text-gray-400 my-1">
                            <span>{userState?.address?.city} | {userState?.address?.country}</span>
                            <span>{userState?.email}</span>
                            <span>{userState?.phoneNumber}</span>
                            <span>{moment(userState?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                            <SwitchStatusComponent isActive={userState?.isActive} isTreated={userState?.isTreated??false} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-4 relative items-center justify-center shadow-lg  rounded-2xl">
              <div className="text-center flex-auto justify-center">
                <h2 className="text-xl font-bold py-4 ">{userState?.role=="candidate"?"Cv":"Kbis"}</h2>
                
                <a href={
                  userState?.role=="candidate"?
                  userState?.profilemedia?.filter((o:any)=>o.type=="cvPdf")[0].fullUrl
                  :
                  userState?.companyProfile?.kbis?.url
                } target="_blank" download type="link" className="text-slate-600 text-lg underline">Télécharger</a>
              </div>
              <div className="p-3 text-center space-x-4 md:block">
                <Popover
                  placement="bottom"
                  open={isOpen}
                  trigger="click"
                  content={(
                    <div className="w-72">
                      <Form layout="vertical">
                        <InputComponent value={motif} onChange={(e:any)=>setMotif(e.target.value)} type="textarea" label="Motif du blocage" inputClassName="bg-slate-200 focus:bg-slate-200 hover:bg-slate-200" />
                      </Form>
                      <div className="flex gap-5 justify-end">
                        <Button onClick={()=>setIsOpen(false)} className="bg-slate-500 border-none text-white hover:!text-white">Annuler</Button>
                        <Button onClick={()=>handleSendRejectMotif()} className="bg-red-500 border-none text-white hover:!text-white">Envoyer</Button>
                      </div>
                    </div>
                  )}
                >
                  <Button onClick={() => setIsOpen(true)} className="bg-slate-500 border-none text-white hover:bg-slate-500 hover:!text-white">Bloquer</Button>
                </Popover>
                <Popconfirm
                  title="Vous êtes sure ?"
                  description=" "
                  onConfirm={() => handleSendActivation()}
                  onCancel={() => null}
                  okText="Oui"
                  okButtonProps={{ className: "bg-emerald-500 text-white hover:!bg-emerald-500 border-none hover:!text-white" }}
                  cancelButtonProps={{ className: "bg-red-500 text-white hover:!bg-red-500 border-none hover:!text-white" }}
                  cancelText="Non" >
                  <Button className="bg-slate-500 border-none text-white hover:bg-slate-500 hover:!text-white">Activer</Button>
                </Popconfirm>
              </div>
            </div>
          </div>
          <div className={validationState?.length == 0?"hidden":"block"}>
            <div className="shadow-lg p-4 rounded-2xl">
              <span className="capitalize font-semibold">Historiques</span>
              <ul className="p-2 flex flex-col divide-y overflow-y-scroll max-h-64 mt-3">
                {validationState.map((v:any)=>{
                  return <li className="py-2 flex flex-col">
                    <span><span className="text-slate-500">Motif</span> : {v.motif}</span>
                    <span><span className="text-slate-500">Créer le</span> : {moment(v.createdAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                  </li>
                })}
              </ul>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default DetailPage