import axios from "axios"
import { env } from "core/env"
import { IService } from "shared/interfaces/service.interface"
import { axiosInstance } from "shared/utils/axios.util"
import { getAuthToken } from "shared/utils/localstorage.util"


const ENDPOINT = 'auth/'

export const authService = {

  register : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}login`,data)
  },

  login : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}login`,data)
  },

  forgot : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}forgot`,data)
  },

  resend : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}resend`,data)
  },

  verify : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}verify-code`,data)
  },

  reset : async ({data}:IService) => {
    return axiosInstance.post(`${ENDPOINT}reset-password`,data)
  },

}