import { message, Form, Button, Divider, notification } from "antd";
import InputComponent from "components/Input.component";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login } from "store/actions/auth.action";

const LoginPage: React.FC = () => {

  const navigate:any = useNavigate()
  const dispatch:any = useDispatch()
  const [pushNotif, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const [loginForm] = React.useState({
    email: null,
    password: null,
  })

  const validateMessages = {
    required: '${label} est requis',
    types: {
      email: '${label} n\'est pas un email valide',
      number: '${label} n\'est pas un numéro valide',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const handleLoginForm = React.useCallback(async (data:any)=>{
    let response = await dispatch(login({data:data}))
    console.log(response);
    
    pushNotif.open({
      type:!response.error?"success":"error",
      message:response.label,
      description:response.message,
      onClose:()=>{
        if(!response.error) {
          navigate('/admin')
        }
      }
    })
    navigate('/admin')
  },[])


  return (
    <div className="login-page flex-1 shadow-lg bg-white rounded-md p-5 mx-5">
      {contextHolder}
      <div className="page-wrapper">
        <div className="form">
          <div className="form-wrapper flex flex-col gap-5">
            <div className="form-header flex flex-col items-center justify-center gap-2">
              
              <h1 className="uppercase font-semibold text-2xl text-primary">Bienvenue</h1>
              <p className="text-sm">Veuillez s'identifier pour accéder au tableau de bord</p>
            </div>
            <div className="form-body">
              <Form
                validateMessages={validateMessages}
                form={form}
                onFinish={handleLoginForm}
                initialValues={{ ...loginForm }}
                className="flex flex-col gap-4" 
                layout="vertical">
                <InputComponent 
                  inputClassName="!bg-slate-200 hover:bg-slate-200"
                  size="large"
                  name="email"
                  rules={[
                    {required:true}
                  ]}
                  labelClassName="m-0" 
                  label="Email"/>
                <InputComponent 
                  inputClassName="!bg-slate-200 hover:bg-slate-200"
                  labelClassName="m-0" 
                  size="large" 
                  name="password"
                  rules={[
                    {required:true}
                  ]}
                  type="password" 
                  label="Mot de passe" />
              </Form>
            </div>
            <div className="form-footer flex flex-col">
              <Button onClick={()=>form.submit()} className="bg-primary text-white border-none self-end hover:!text-white" >S'identifier</Button>
              <Divider  className="my-2"/>
              {/* <div className="flex items-center justify-between">
                <p className="text-xs">Vous avez oublié votre mot de passe ?</p>
                <Button onClick={()=>navigate('forgot')} type="link" className="text-xs text-secondary hover:!text-primary" >Récupérez mot de passe</Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default LoginPage