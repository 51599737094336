import { IAction } from "shared/interfaces/action.interface"
import storeTypes from "store/type"


const initialState:any = {
  list:[],
  detail:null
}

const validationReducer = (state = initialState, action:IAction) => {

  switch (action.type) {
    case storeTypes.validation.list:
      return state = {
        ...state,
        list: action.payload
      }
      case storeTypes.validation.clear:
        return state = {
          ...state,
          detail: null,
          list: [],
        }
    default:
      return state = {...state}
  }
  
}

export default validationReducer