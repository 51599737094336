import ForgotPage from "pages/auth/Forgot.page";
import LoginPage from "pages/auth/Login.page";
import ResetPage from "pages/auth/Reset.page";
import { RouteObject } from "react-router-dom";




export const authRoutes: RouteObject[] = [
  {
    index: true,
    element: <LoginPage />,
  },
  {
    path: 'forgot',
    element: <ForgotPage />
  },
  {
    path: 'reset',
    element: <ResetPage />
  }
]