import { Tag } from "antd"

const SwitchStatusComponent = ({isActive = false,isTreated = false}:any) => {
  
  let status = ''
  let color = ''

  if((!isActive || isActive) && !isTreated) {
    color = "bg-orange-500"
    status = "En attente"
  }

  if(!isActive && isTreated) {
    color = "bg-red-500"
    status = "Bloqué"
  }

  if(isActive && isTreated) {
    color = "bg-emerald-500"
    status = "Activé"
  }
  
  return(
    <Tag className={color+" text-white text-md w-fit uppercase px-2 text-center font-semibold"}>{status}</Tag>
  )
}

export default SwitchStatusComponent